@font-face {
  font-family: 'Courier New';
  src: local('Courier New'), url(./assets/fonts/cour.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Courier New Italic';
  src: local('Courier New Italic'), url(./assets/fonts/couri.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.fira-sans {
  font-family : Fira Sans
}

.courier-new {
  font-family : Courier New
}

.courier-new-i {
  font-family : Courier New Italic
}
